<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" class="query-form">
      <el-form-item label="设备号" prop="device">
        <el-input
          v-model="queryParams.device"
          placeholder="请输入设备号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="司机电话" prop="driverPhone">
        <el-input
          v-model="queryParams.driverPhone"
          placeholder="请输入司机电话"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="车牌号" prop="vehicleNumber">
        <el-input
          v-model="queryParams.vehicleNumber"
          placeholder="请输入车牌号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table border stripe v-loading="loading" :data="shippingList" @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <el-table-column label="序号" fixed align="center" type="index" />
      <el-table-column label="设备号" align="center" prop="device">
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime">
        <template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>
      </el-table-column>
      <el-table-column label="司机姓名" align="center" prop="driverName" />
      <el-table-column label="司机电话" align="center" prop="driverPhone" />
      <el-table-column label="车牌号" align="center" prop="vehicleNumber">
      </el-table-column>
      <el-table-column label="操作" fixed align="center" width="50" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-dropdown size="small" @command="(command) => handleCommand(command, scope.row)">
            <span class="el-dropdown-link">
              <i class="ivu-icon ivu-icon-md-settings" style="font-size:16px;padding:9px;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="!scope.row.vehicleNumber" command="toVehicle" icon="el-icon-view">查看车辆绑定</el-dropdown-item>
              <el-dropdown-item :disabled="!scope.row.device" command="toDevice" icon="el-icon-view">查看设备绑定</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { listDeviceDriver } from "@/api/schedule/devicedriver";
import Pagination from "./components/pagination.vue"

export default {
  name: "DeviceDriver",
  components: {Pagination},
  props: {
    number: String
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 设备围栏关联表表格数据
      shippingList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        device: null,
        driverPhone: null,
        vehicleNumber: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      if (this.number) {
        this.queryParams.shippingNoteNumber = this.number
      }
      this.getList()
    },
    /** 查询设备围栏关联表列表 */
    getList() {
      this.loading = true;
      let form = Object.fromEntries(
        Object.entries(this.queryParams).map(
          ([k, v]) => [k, v === '' ? null : v]
        )
      )
      listDeviceDriver(form).then(response => {
        this.shippingList = response.data.list;
        this.total = response.data.count;
      }).finally(() => {
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs.queryForm.resetFields()
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    toDevice(row) {
      this.$router.push({path: '/schedule/device', query: {device: row.device}})
    },
    toVehicle(row) {
      this.$router.push({path: '/schedule/device', query: {vehicle: row.vehicleNumber}})
    },
    handleCommand(command, row) {
      switch(command) {
        case 'toDevice':
          this.toDevice(row);
          return;
        case 'toVehicle':
          this.toVehicle(row);
          return;
      }
    }
  }
};
</script>
